import React from "react"

const Upvote = ({ changeVote, upvoted }) => {
  const styles = {
    arrowStyles: {
      cursor: "pointer",
      width: "30px",
      fill: upvoted ? "#2ecc71" : "#B4CBEA",
    },
  }
  return (
    <svg
      onClick={() => changeVote(upvoted ? -1 : 1)}
      style={styles.arrowStyles}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 169 158"
    >
      <path d="M8.5,133.7V72.9c0-1.6,0.6-3.1,1.8-4.3c1.2-1.2,2.6-1.8,4.3-1.8h27.4c1.6,0,3.1,0.6,4.3,1.8c1.2,1.2,1.8,2.6,1.8,4.3     v60.8c0,1.6-0.6,3.1-1.8,4.3c-1.2,1.2-2.6,1.8-4.3,1.8H14.6c-1.6,0-3.1-0.6-4.3-1.8C9.1,136.8,8.5,135.4,8.5,133.7z M20.7,121.6     c0,1.7,0.6,3.2,1.8,4.3c1.2,1.2,2.6,1.8,4.3,1.8c1.6,0,3.1-0.6,4.3-1.8c1.2-1.2,1.8-2.6,1.8-4.3c0-1.6-0.6-3.1-1.8-4.3     c-1.2-1.2-2.6-1.8-4.3-1.8c-1.7,0-3.2,0.6-4.3,1.8C21.3,118.5,20.7,119.9,20.7,121.6z M54.1,133.7V72.8c0-1.6,0.6-3,1.7-4.1     c1.1-1.2,2.5-1.8,4.1-1.9c1.5-0.1,3.9-2,7.2-5.6c3.3-3.6,6.5-7.4,9.6-11.5c4.3-5.5,7.5-9.3,9.6-11.4c1.1-1.1,2.1-2.7,2.9-4.6     c0.8-1.9,1.4-3.4,1.7-4.6c0.3-1.2,0.7-3.1,1.3-5.7c0.4-2.5,0.8-4.4,1.2-5.8c0.3-1.4,1-3,1.9-4.9c0.9-1.9,2-3.5,3.2-4.7     c1.2-1.2,2.6-1.8,4.3-1.8c2.9,0,5.5,0.3,7.8,1c2.3,0.7,4.2,1.5,5.7,2.5c1.5,1,2.8,2.3,3.8,3.8c1,1.6,1.8,3,2.3,4.3     c0.5,1.3,0.9,2.8,1.1,4.7c0.3,1.9,0.4,3.3,0.5,4.3c0,1,0,2.2,0,3.7c0,2.4-0.3,4.8-0.9,7.2c-0.6,2.4-1.2,4.3-1.8,5.7     c-0.6,1.4-1.5,3.2-2.6,5.3c-0.2,0.4-0.5,0.9-0.9,1.7c-0.4,0.8-0.8,1.5-1,2.1c-0.3,0.6-0.5,1.4-0.8,2.3h26.3     c4.9,0,9.2,1.8,12.8,5.4s5.4,7.9,5.4,12.8c0,5.4-1.7,10.2-5.2,14.2c0.9,2.8,1.4,5.2,1.4,7.2c0.2,4.8-1.2,9.2-4.1,13     c1.1,3.5,1.1,7.3,0,11.1c-0.9,3.6-2.7,6.6-5.1,8.9c0.6,7.1-1,12.8-4.7,17.2c-4.1,4.8-10.3,7.3-18.7,7.4h-3.4h-7.2h-1.6     c-4.2,0-8.7-0.5-13.7-1.5c-4.9-1-8.8-1.9-11.5-2.8c-2.8-0.9-6.6-2.1-11.4-3.8c-7.8-2.7-12.8-4.1-15-4.2c-1.6-0.1-3.1-0.7-4.3-1.9     C54.7,136.8,54.1,135.4,54.1,133.7z" />
    </svg>
  )
}

export default Upvote
